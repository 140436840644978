import { Snackbar, Alert } from '@mui/material'
import React, { useEffect, useState } from 'react'

function Alerts(props) {
    const [open, setOpen] = useState(false);

    useEffect(()=>{
      setOpen(props.alert)
      setTimeout(()=>{
        setOpen(false)
      },3000)
    },[props.alert])

  return (
    <>
    <Snackbar
    anchorOrigin={{vertical:'top', horizontal:"center"}}
    open={open}
    >
    <Alert severity={props.status}>{props.text}</Alert>
    </Snackbar>
    </>
  )
}

export default Alerts;