import styles from "./style.module.css";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import PercentIcon from '@mui/icons-material/Percent';

export default function MultiFields({ data }) {
  const { name, value, type, show, isPercentage= false } = data;
  return (
    <>
      {value ? (
        <div className={styles.parent}>
          <span className={styles.fieldName}>{name}</span>

          <div className={styles.rightFields}>
            <div className={styles.alignment}>
              <span className={styles.valueName}>
                <CurrencyRupeeIcon className={styles.iconSize} />
                {value}
              </span>
            </div>
            {(type || show) && (
              <div className={styles.rightAlignment}>
                {isPercentage ? (
            <span className={styles.valueName}>
              {type}
              <PercentIcon className={styles.iconSize} />
            </span>
          ) : (
            <span className={styles.valueOption}>{type}</span>
          )}
                <span className={styles.valueOption}>{show}</span>
              </div>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
