import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function Navigation({
  route = "",
  buttonText = "",
  buttonIcon = "",
}) {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate(route);
  };

  return (
    <Button onClick={handleNavigation} startIcon={buttonIcon}>
      {buttonText}
    </Button>
  );
}
