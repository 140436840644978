// assets
import { IconUserCircle } from "@tabler/icons";

// constant
const icons = { IconUserCircle };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
  id: 'employee',
  title: 'Employee',
  type: 'group',
  children: [
    {
      id: "employeeList",
      title: "Employee List",
      type: 'item',
      url: "/employee/list",
      icon: icons.IconUserCircle,
      breadcrumbs: false,
    },
    {
      id: "addEmployee",
      title: "Add New Employee",
      type: "item",
      url: "/employee/add",
      icon: icons.IconUserCircle,
      breadcrumbs: false,
    },
  ],
};

export default dashboard;
