export const services = ["Hotel", "Visa", "Flight"];

export const paymentMode = ["BC", "CC", "RC"];

export const optionOne = ["RB", "VL", "RT", "NB", "NT"];

export const optionTwo = ["N", "S", "B", "H", "Z"];

export const clientData = [
  { name: "client_taxes", fieldName: "Taxes (+)", optionOne: null },
  {
    name: "client_discount",
    fieldName: "Discount (-)",
    optionOne: true,
    optionTwo: true,
    negative: true,
  },
  {
    name: "Charges",
    list: [
      {
        name: "client_serviceChrg",
        fieldName: "Serv. Chrg (+)",
        optionTwo: true,
      },
      {
        name: "client_miscChrg",
        fieldName: "Misc. Chrg (+)",
        optionTwo: true,
      },
      {
        name: "client_deliveryChrg",
        fieldName: "Delv. Chrg (+)",
        optionTwo: true,
      },
      {
        name: "client_brokerage",
        fieldName: "Brokerage",
        optionOne: true,
      },
    ],
  },
  {
    name: "GST",
    list: [
      { name: "client_iGST", fieldName: "I GST (+)" },
      { name: "client_cGST", fieldName: "C GST (+)" },
      { name: "client_sGST", fieldName: "S GST (+)" },
    ],
  },
  {
    name: "TDS",
    list: [
      { name: "client_tds", fieldName: "TDS (+)" },
      { name: "client_tcs", fieldName: "TCS (+)" },
      { name: "client_tds_f16", fieldName: "TDS F-16 (-)", negative: true },
    ],
  },
];

export const supplierData = [
  { name: "supplier_taxes", fieldName: "Taxes (+)", optionOne: null },
  {
    name: "supplier_discount",
    fieldName: "Discount (-)",
    optionOne: true,
    optionTwo: true,
    negative: true,
  },
  {
    name: "Charges",
    list: [
      {
        name: "supplier_serviceChrg",
        fieldName: "Serv. Chrg (+)",
        optionTwo: true,
      },
      {
        name: "supplier_miscChrg",
        fieldName: "Misc. Chrg (+)",

        optionTwo: true,
      },
      {
        name: "supplier_deliveryChrg",
        fieldName: "Delv. Chrg (+)",

        optionTwo: true,
      },
      {
        name: "supplier_brokerage",
        fieldName: "Brokerage",
        optionOne: true,
      },
    ],
  },
  {
    name: "GST",
    list: [
      { name: "supplier_iGST", fieldName: "I GST (+)" },
      { name: "supplier_cGST", fieldName: "C GST (+)" },
      { name: "supplier_sGST", fieldName: "S GST (+)" },
    ],
  },
  {
    name: "TDS",
    list: [
      { name: "supplier_tds", fieldName: "TDS (+)" },
      { name: "supplier_tcs", fieldName: "TCS (+)" },
      { name: "supplier_tds_f16", fieldName: "TDS F-16 (-)", negative: true },
    ],
  },
];

export const initialApiSchema = {
  client_taxes_amount: "",
  client_discount_amount: "",
  client_discount_type: "",
  client_discount_show: "",
  client_serviceChrg_amount: "",
  client_serviceChrg_percent: "",
  client_serviceChrg_show: "",
  client_miscChrg_amount: "",
  client_miscChrg_percent: "",
  client_miscChrg_show: "",
  client_deliveryChrg_amount: "",
  client_deliveryChrg_percent: "",
  client_deliveryChrg_show: "",
  client_iGST_amount: "",
  client_iGST_percent: "",
  client_cGST_amount: "",
  client_cGST_percent: "",
  client_sGST_amount: "",
  client_sGST_percent: "",
  client_tds_amount: "",
  client_tds_percent: "",
  client_tds_f16_amount: "",
  client_tds_f16_percent: "",
  client_tcs_amount: "",
  client_tcs_percent: "",
  client_net_amount: "",
  client_brokerage_amount: "",
  client_brokerage_type: "",

  supplier_taxes_amount: "",
  supplier_discount_amount: "",
  supplier_discount_type: "",
  supplier_discount_show: "",
  supplier_serviceChrg_amount: "",
  supplier_serviceChrg_percent: "",
  supplier_serviceChrg_show: "",
  supplier_miscChrg_amount: "",
  supplier_miscChrg_percent: "",
  supplier_miscChrg_show: "",
  supplier_deliveryChrg_amount: "",
  supplier_deliveryChrg_percent: "",
  supplier_deliveryChrg_show: "",
  supplier_iGST_amount: "",
  supplier_iGST_percent: "",
  supplier_cGST_amount: "",
  supplier_cGST_percent: "",
  supplier_sGST_amount: "",
  supplier_sGST_percent: "",
  supplier_tds_amount: "",
  supplier_tds_percent: "",
  supplier_tds_f16_amount: "",
  supplier_tds_f16_percent: "",
  supplier_tcs_amount: "",
  supplier_tcs_percent: "",
  supplier_net_amount: "",
  supplier_brokerage_amount: "",
  supplier_brokerage_type: "",
  supplier_commission: "",
};

export const initialMap = [
  ["supplierName", ""],
  ["supplierId", ""],
  ["sacCode", ""],
  ["recptionEmployId", ""],
  ["enquiryId", ""],
  ["enquiryNo", ""],
  ["bookingType", ""],
  ["client_payment_pending", true],
  ["supplier_payment_pending", true],
  ["client_payment_mode", ""],
  ["supplier_payment_mode", ""],
  ["clientName", ""],
  ["handledBy", ""],
];
