import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useGetQueryQuery } from 'store/crm'
import { Typography } from '@mui/material'
import moment from 'moment'

function createData (name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein }
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9)
]

export default function BasicTable () {
  const { data } = useGetQueryQuery()

  console.log('data', data)
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell>EnquiryNo</TableCell>
            <TableCell>CustomerName</TableCell>
            <TableCell>HandledBy</TableCell>
            <TableCell>Billing Status</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Billied At</TableCell>
           
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.data?.map(row => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>{row.enquiryNo}</TableCell>
              <TableCell>{row.customerName}</TableCell>
              <TableCell>{row.handledBy}</TableCell>
              <TableCell>
                {row.transactionsDetails?.map(item => {
                  return (
                    <>
                    <Typography sx={{fontSize:"12px"}}>
                      {item?.billing_completed ? (
                        <>{item?.accountEmployName} </>
                      ) : (
                        <>Due</>
                      )}
                      </Typography>
                      <br/>
                    </>
                  )
                })}
              </TableCell>
              <TableCell>
                {row.transactionsDetails?.map(item => {
                  return (
                    <>
                    <Typography sx={{fontSize:"12px"}}>
                      {item?.billing_completed ? (
                        <>{item?.client_net_amount} </>
                      ) : (
                        <>Due</>
                      )}
                      </Typography>
                      <br/>
                    </>
                  )
                })}
              </TableCell>
             
              <TableCell>
                {row.transactionsDetails?.map(item => {
                  return (
                    <>
                    <Typography sx={{fontSize:"12px"}}>
                      {item?.billing_completed ? (
                        <>{moment(item?.createdAt).format("dddd, MMMM Do YYYY")} </>
                      ) : (
                        <>Due</>
                      )}
                      </Typography>
                      <br/>
                    </>
                  )
                })}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
