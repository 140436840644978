export default function useGetTransactionSchema(
  transactionData,
  supplierApiData,
  clientApiData
) {
  let supplierData = [];
  let clientData = [];
  let commonData = [];
  let supplierDetail = [];
  let clientDetail = [];
  let names = { clientName: "", supplierName: "" };
  let otherValues = { profit: "", clientNet: "", supplierNet: "" };

  if (transactionData) {
    const {
      _id,
      supplierName,
      clientName,
      handledBy,
      accountEmployName,
      sacCode,
      enquiryNo,
      bookingType,
      billing_completed,
      client_payment_pending,
      client_payment_mode,
      client_basic_amount,
      client_discount_amount,
      client_discount_type,
      client_discount_show,
      client_iGST_amount,
      client_iGST_percent,
      client_cGST_amount,
      client_cGST_percent,
      client_sGST_amount,
      client_sGST_percent,
      client_tds_amount,
      client_tds_percent,
      client_tds_f16_amount,
      client_tds_f16_percent,
      client_tcs_amount,
      client_tcs_percent,
      client_net_amount,
      client_serviceChrg_amount,
      client_serviceChrg_percent,
      client_serviceChrg_show,
      client_miscChrg_amount,
      client_miscChrg_percent,
      client_miscChrg_show,
      supplier_payment_pending,
      supplier_payment_mode,
      supplier_basic_amount,
      supplier_serviceChrg_amount,
      supplier_serviceChrg_percent,
      supplier_serviceChrg_show,
      supplier_miscChrg_amount,
      supplier_miscChrg_percent,
      supplier_miscChrg_show,
      supplier_iGST_amount,
      supplier_iGST_percent,
      supplier_cGST_amount,
      supplier_cGST_percent,
      supplier_sGST_amount,
      supplier_sGST_percent,
      supplier_tds_amount,
      supplier_tds_percent,
      supplier_tds_f16_amount,
      supplier_tds_f16_percent,
      supplier_tcs_amount,
      supplier_tcs_percent,
      supplier_net_amount,
      supplier_commission,
      createdAt,
    } = transactionData;

    if (supplierApiData) {
      const { Email, MobileNo, GSTState, GSTNo } = supplierApiData;
      supplierDetail = [
        { name: "Mobile No:", value: MobileNo },
        { name: "Email:", value: Email },
        { name: "GST State:", value: GSTState },
        { name: "GST No.:", value: GSTNo },
      ];
    }

    if (clientApiData) {
      const { Email, MobileNo, GSTState, GSTNo } = clientApiData;
      clientDetail = [
        { name: "Mobile No:", value: MobileNo },
        { name: "Email:", value: Email },
        { name: "GST State:", value: GSTState },
        { name: "GST No.:", value: GSTNo },
      ];
    }

    supplierData = [
      {
        name: "Basic Amount:",
        value: supplier_basic_amount,
        isRupeeSign: true,
      },
      {
        name: "Charges:",
        list: [
          {
            name: "Service:",
            value: supplier_serviceChrg_amount,
            type: supplier_serviceChrg_percent,
            show: supplier_serviceChrg_show,
            isRupeeSign: true,
            isPercentage: true,
          },
          {
            name: "Misc.:",
            value: supplier_miscChrg_amount,
            type: supplier_miscChrg_percent,
            show: supplier_miscChrg_show,
            isRupeeSign: true,
            isPercentage: true,
          },
        ],
      },
      {
        name: "Commissions:",
        list: [
          {
            name: "Commission:",
            value: supplier_commission || 0,
            type: null,
            show: null,
            isRupeeSign: true,
          },
        ],
      },
      {
        name: "GST:",
        list: [
          {
            name: "iGST:",
            value: supplier_iGST_amount,
            type: supplier_iGST_percent,
            isRupeeSign: true,
            isPercentage: true,
          },
          {
            name: "cGST:",
            value: supplier_cGST_amount,
            type: supplier_cGST_percent,
            isRupeeSign: true,
            isPercentage: true,
          },
          {
            name: "sGST:",
            value: supplier_sGST_amount,
            type: supplier_sGST_percent,
            isRupeeSign: true,
            isPercentage: true,
          },
        ],
      },
      {
        name: "TCS/TDS:",
        list: [
          {
            name: "TCS:",
            value: supplier_tcs_amount,
            type: supplier_tcs_percent,
            isRupeeSign: true,
            isPercentage: true,
          },
          {
            name: "TDS:",
            value: supplier_tds_amount,
            type: supplier_tds_percent,
            isRupeeSign: true,
            isPercentage: true,
          },
          {
            name: "TDS_F16:",
            value: supplier_tds_f16_amount,
            type: supplier_tds_f16_percent,
            isRupeeSign: true,
            isPercentage: true,
          },
        ],
      },

      {
        name: "Pay. Pending:",
        value: supplier_payment_pending === false ? "Paid" : "Unpaid",
      },
      {
        name: "Pay. Mode:",
        value: supplier_payment_mode,
      },
    ];

    clientData = [
      {
        name: "Basic Amount:",
        value: client_basic_amount,
        isRupeeSign: true,
      },
      {
        name: "Charges:",
        list: [
          {
            name: "Service:",
            value: client_serviceChrg_amount,
            type: client_serviceChrg_percent,
            show: client_serviceChrg_show,
            isRupeeSign: true,
            isPercentage: true,
          },
          {
            name: "Misc.:",
            value: client_miscChrg_amount,
            type: client_miscChrg_percent,
            show: client_miscChrg_show,
            isRupeeSign: true,
            isPercentage: true,
          },
        ],
      },
      {
        name: "Discounts:",
        list: [
          {
            name: "Discount:",
            value: client_discount_amount,
            type: client_discount_type,
            show: client_discount_show,
            isRupeeSign: true,
            isPercentage: true,
          },
        ],
      },
      {
        name: "GST:",
        list: [
          {
            name: "iGST:",
            value: client_iGST_amount,
            type: client_iGST_percent,
            isRupeeSign: true,
            isPercentage: true,
          },
          {
            name: "cGST:",
            value: client_cGST_amount,
            type: client_cGST_percent,
            isRupeeSign: true,
            isPercentage: true,
          },
          {
            name: "sGST:",
            value: client_sGST_amount,
            type: client_sGST_percent,
            isRupeeSign: true,
            isPercentage: true,
          },
        ],
      },

      {
        name: "TCS/TDS:",
        list: [
          {
            name: "TCS:",
            value: client_tcs_amount,
            type: client_tcs_percent,
            isRupeeSign: true,
            isPercentage: true,
          },
          {
            name: "TDS:",
            value: client_tds_amount,
            type: client_tds_percent,
            isRupeeSign: true,
            isPercentage: true,
          },
          {
            name: "TDS_F16:",
            value: client_tds_f16_amount,
            type: client_tds_f16_percent,
            isRupeeSign: true,
            isPercentage: true,
          },
        ],
      },

      {
        name: "Pay. Pending:",
        value: client_payment_pending === false ? "Paid" : "Unpaid",
      },
      {
        name: "Pay. Mode:",
        value: client_payment_mode,
      },
    ];

    commonData = [
      { name: "Enquiry No:", value: enquiryNo },
      {
        name: "Billing Date:",
        value: new Date(createdAt)?.toLocaleDateString("en-IN") || "",
      },
      { name: "Booking Type:", value: bookingType },
      { name: "SAC Code:", value: sacCode },
      { name: "Handled By:", value: handledBy },
      { name: "Approved By:", value: accountEmployName },
    ];
    names = { clientName: clientName, supplierName: supplierName };

    otherValues = {
      profit:
        parseFloat(client_serviceChrg_amount) || 0,
          // parseFloat(supplier_commission) || 0,
      clientNet: client_net_amount,
      supplierNet: supplier_net_amount,
      supplierPaymentPending: supplier_payment_pending,
      supplierPaymentMode: supplier_payment_mode,
      clientPaymentPending: client_payment_pending,
      clientPaymentMode: client_payment_mode,
      transactionId: _id,
      billingCompleted: billing_completed,
    };
  }

  return {
    supplierData,
    supplierDetail,
    clientData,
    clientDetail,
    commonData,
    names,
    otherValues,
  };
}
