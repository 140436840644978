import * as React from 'react'
import QueryFilter from '../QueryFilter'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import style from './style.module.css'
import EditCustomer from './customerDetails'
import Button from '@mui/material/Button'
import { BiSolidDownArrow } from 'react-icons/bi'
import { Accordion, AccordionDetails, AccordionSummary, Card, InputAdornment, TextField, Typography } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom'
import TableNavigation from '../TableNavigation'
import { MdDelete } from "react-icons/md";
import { TbEdit } from "react-icons/tb";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#64ccc5',
    color: theme.palette.common.white,
    fontFamily: 'souge',
    fontWeight: '500',
    border:'1px solid gray'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13.5,
    fontFamily: 'comfortaa',
    border:'1px solid gray'
   

  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
    border:'1px solid gray'
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border:'1px solid gray'
  }
}))

export default function CustomizedTables () {
  let navigate = useNavigate();
  const [result, setResult] = React.useState([])
  const [search, setSearch] = React.useState([])
  const [sortByStatus, setSortByStatus] = React.useState({
    pending:false,
    quoted:false,
    confirm:false,
    canceled:false,
    working:false,
    lost:false
  })

  const [data, setData] = React.useState("")
  const [page, setPage] = React.useState("1")

  const handleTableData = (val) => {
    setData(val);
  };

  const handlePageChange = (val) => {
    setPage(val);
  };



  React.useEffect(() => {
    if (data) {
   


      let sortedArr = data.data

      sortedArr = sortedArr
        ?.slice()
        .sort((a, b) => b.updatedAt.localeCompare(a.updatedAt))
 
      setResult(sortedArr)
    }
  }, [data])



  const sortBylatest = (data)=>{
    let sortedArr = data
   return  sortedArr
      ?.slice()
      .sort((a, b) => b.updatedAt.localeCompare(a.updatedAt))

  }





  
  const handleHandleBySort = () => {
    let sortedArr = data.data

    sortedArr = sortedArr
      ?.slice()
      .sort((a, b) => a.handledBy.localeCompare(b.handledBy))

    setResult(sortedArr)
  }

  const handleSearch = e => {
    setSearch(e.target.value)
    setSortByStatus({
      pending:false,
      quoted:false,
      confirm:false,
      canceled:false,
      working:false,
      lost:false
    })

    const resultArray = data.data.filter(
      element =>
        element.handledBy
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        element.enquiryNo
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        element.customerName
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        element.contactNo.toLowerCase().includes(e.target.value.toLowerCase())


    )

    

    setResult(sortBylatest(resultArray))
  }

  const handleClick = () => {}

  const handleSortPending = ()=>{
    setSortByStatus({
      pending:true,
      quoted:false,
      confirm:false,
      canceled:false,
      working:false,
      lost:false
    })
    let sortedArr = data.data.filter((d)=>d.status==='pending')
    setResult(sortBylatest(sortedArr))
  }

  const handleSortQuoted = ()=>{
    setSortByStatus({
      pending:false,
      quoted:true,
      confirm:false,
      canceled:false,
      working:false,
      lost:false
    })

    let sortedArr = data.data.filter((d)=>d.status==='quoted')
    setResult(sortBylatest(sortedArr))

  }

  const handleSortConfirm = ()=>{
    setSortByStatus({
      pending:false,
      quoted:false,
      confirm:true,
      canceled:false,
      working:false,
      lost:false
    })
    let sortedArr = data.data.filter((d)=>d.status==='confirm')
    setResult(sortBylatest(sortedArr))

  }

  const handleSortCanceled = ()=>{
    setSortByStatus({
      pending:false,
      quoted:false,
      confirm:false,
      canceled:true,
      working:false,
      lost:false
    })
    let sortedArr = data.data.filter((d)=>d.status==='canceled')
    setResult(sortBylatest(sortedArr))

  }

  const handleSortWorking = ()=>{
    setSortByStatus({
      pending:false,
      quoted:false,
      confirm:false,
      canceled:false,
      working:true,
      lost:false
    })
    let sortedArr = data.data.filter((d)=>d.status==='working')
    setResult(sortBylatest(sortedArr))

  }


  const handleSortLost = ()=>{
    setSortByStatus({
      pending:false,
      quoted:false,
      confirm:false,
      canceled:false,
      working:false,
      lost:true
    })
    let sortedArr = data.data.filter((d)=>d.status==='lost')
    setResult(sortBylatest(sortedArr))

  }



  return (
    <>
    <div className={style.btnDiv}>
    </div>
      <Card
        sx={{
          p: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          pt: 0.3
        }}
        elevation={0}
      >
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection:'column'
          }}
        >
          <Typography sx={{ fontFamily: 'souge', fontSize: '18px' }}>
            <>{result.length}</>
          </Typography>
          <Typography
            sx={{
              fontFamily: 'souge',
              mt: -0.5,
              fontSize: 13,
              ml: -0.9,
              color: 'let(--prime)',
              fontWeight:'13px'
            }}
          >
            Queries
          </Typography>
        </Typography>
        <TextField
          value={search}
          onChange={handleSearch}
          id='outlined-start-adornment'
          size='lg'
          placeholder='Search Customer Name, HandleBy, Enquiry No. etc...'
          sx={{ m: 1.5, width: '80%', mr: 3 }}
          InputProps={{
            style: {
              fontFamily: 'Comfortaa',
              letterSpacing: -0.5,
              fontSize: 11,
              height: '2.5rem'
            },
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <>
                {search.length ? (
              
                    <InputAdornment position='start'>
                      <ClearIcon
                        sx={{
                          color: 'gray',
                          opacity: 0.6,
                          fontSize: 20
                        }}
                        onClick={handleClick}
                      />
                    </InputAdornment>
             
                ) : null}
              </>
            )
          }}
        />
       
       <Button variant="contained" color="primary" sx={{borderRadius:2.5}}>
         Search
       </Button>

      </Card>
     

      <div >
      <Accordion className={style.accord}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{color:'white'}}/>}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{bgcolor:'#176B87',color:'white',fontFamily:'souge',borderRadius:1}}
        >
          <Typography>Filter</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <div className={style.sortDiv}>
          <div className={style.sortByStatus}>
          <div className={style.sortTitle}>Status :</div>
          <Button size='small' variant={sortByStatus.quoted?"contained":"outlined"} onClick={handleSortQuoted} color="secondary" sx={{textTransform:'none'}}>
            Quoted
          </Button>
          <Button size='small'variant={sortByStatus.pending?"contained":"outlined"} onClick={handleSortPending} color="warning" sx={{textTransform:'none'}}>
            pending
          </Button>
          <Button size='small' variant={sortByStatus.canceled?"contained":"outlined"} onClick={handleSortCanceled} color="info" sx={{textTransform:'none'}}>
            Canceled
          </Button>
          {/* <Button size='small' variant={sortByStatus.working?"contained":"outlined"} onClick={handleSortWorking} color="primary" sx={{textTransform:'none'}}>
            working
          </Button> */}
          <Button size='small' variant={sortByStatus.lost?"contained":"outlined"} onClick={handleSortLost} color="error" sx={{textTransform:'none'}}>
           Lost
          </Button>
          <Button size='small' variant={sortByStatus.confirm?"contained":"outlined"} onClick={handleSortConfirm} color="success" sx={{textTransform:'none'}}>
            Confirm
          </Button>
          </div>

         <div className={style.center}><QueryFilter handleTableData={handleTableData} pageValue={page}/></div>
         </div>
        </AccordionDetails>
      </Accordion>
      </div>
 

    

      

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700, maxHeight:'100vh' }} aria-label='customized table'>
          <TableHead>
            <TableRow>
              <StyledTableCell>Enquiry No.</StyledTableCell>
              <StyledTableCell>Customer Name</StyledTableCell>
              <StyledTableCell>Contact No.</StyledTableCell>
           
           
              <StyledTableCell>
                <div className={style.rowData}>
                  Handled By{' '}
                  <BiSolidDownArrow
                    onClick={handleHandleBySort}
                    style={{ fontSize: '10px' }}
                  />
                </div>
              </StyledTableCell>
              <StyledTableCell>Date Of Enquiry</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {result.map(person => (
             
             
        
              <StyledTableRow key={person.id}>
                <StyledTableCell>{person.enquiryNo}</StyledTableCell>
                <StyledTableCell><p className={style.customerName}>{person.customerName}</p></StyledTableCell>
                <StyledTableCell>{person.contactNo}</StyledTableCell>
          
 
                <StyledTableCell>{person.handledBy}</StyledTableCell>
                <StyledTableCell>
                  {localDate(person.createdAt,person.updatedAt)}
                </StyledTableCell>
                <StyledTableCell>
                  <>
                    {person.status === 'pending' &&
                      customButton([person.status], 'warning')}
                    {( person.status === 'confirm' )  &&
                      customButton([person.status], 'success')}
                    {person.status === 'quoted' &&
                      customButton([person.status], 'secondary')}
                    {person.status === 'canceled' &&
                      customButton([person.status], 'info')}
                    {person.status === 'working' &&
                      customButton([person.status], 'primary')}
                    {person.status === 'lost' &&
                      customButton([person.status], 'error')}
                  </>
                </StyledTableCell>
                <StyledTableCell >
                  <span className={style.actionCell}>
                  <EditCustomer client={person} />
                  <MdDelete style={{color:"red"}}/>
                
                  <TbEdit />
                  </span>
                </StyledTableCell>
              </StyledTableRow>

            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TableNavigation data={data} handleChange={handlePageChange}/>
    </>
  )
}

const customButton = (text, color) => {
  return (
    <Button
      variant='contained'
      color={color}
      sx={{ height: '25px', textTransform: 'capitalize' }}
    >
    
      {text=="confirm" ? <>Confirmed</>:<>{text}</>}
      {/* {text} */}
    </Button>
  )
}

const localDate = (createdAt, updatedAt) => {

 

  const createdAtDate =  new Date(createdAt).toLocaleString().split(',')
  const onlyDate = ()=>{
    let splitingDate = createdAtDate[0].split('/')
    let year = splitingDate[2]
    let day = splitingDate[1]
    let month = splitingDate[0]
    
    return `${day}-${month}-${year} `;
  }
  const updatedAtDate =  new Date(updatedAt)
  // const timeDifference = updatedAtDate - createdAtDate;

  // console.log("diff",convertMillisecondsToHMS(timeDifference))

  
  return (
  <p>{onlyDate()}{createdAtDate[1]}</p>
 )
 
}



function convertMillisecondsToHMS(milliseconds) {
  // Convert milliseconds to seconds
  const seconds = Math.floor(milliseconds / 1000);

  // Calculate hours, minutes, and remaining seconds
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  // const remainingSeconds = seconds % 60;

  return `${hours}hr. ${minutes}min.`;
}


