import { useState } from "react";
import MultiFields from "../MultiFields";
import ArrowDropUpRoundedIcon from "@mui/icons-material/ArrowDropUpRounded";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import styles from "../SingleFieldShow/style.module.css";

export default function CollapsibleFields({ listName, list }) {
  const [isShow, setIsShow] = useState(false);

  const handleChange = () => {
    setIsShow((prev) => !prev);
  };

  return (
    <>
      <button className={styles.parent} onClick={handleChange}>
        <span className={styles.fieldName}>{listName}</span>
        <span className={styles.valueName}>
          {isShow ? <ArrowDropUpRoundedIcon /> : <ArrowDropDownRoundedIcon />}
        </span>
      </button>
      {isShow &&
        list?.list.map(({ name, value, type, show, isPercentage }) => (
          <MultiFields
            key={`CollapsibleFields-${name}-${value}`}
            data={{ name, value, show, type, isPercentage }}
          />
        ))}
    </>
  );
}
