// assets
import { IconCurrencyRupee } from "@tabler/icons";

// constant
const icons = { IconCurrencyRupee };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
  id: "accounts",
  title: "Accounts",
  type: "group",
  children: [
    {
      id: "transactionHistory",
      title: "Transaction History",
      type: "item",
      url: "/transaction",
      icon: icons.IconCurrencyRupee,
      breadcrumbs: false,
    },
    {
      id: "ledgerHistory",
      title: "Ledger History",
      type: "item",
      url: "/ledger",
      icon: icons.IconCurrencyRupee,
      breadcrumbs: false,
    },
    {
      id: "billingHistory",
      title: "Billing History",
      type: "item",
      url: "/billing",
      icon: icons.IconCurrencyRupee,
      breadcrumbs: false,
    },
    {
      id: "AccountsRule",
      title: "Accounts Rule",
      type: "item",
      url: "/accountsRule",
      icon: icons.IconCurrencyRupee,
      breadcrumbs: false,
    },
  ],
};

export default dashboard;
