import { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function DropDownMenu({
  options=[{name:"", value: ""}],
  handleChange,
  name = "",
  initialValue = "",
}) {
  const [data, setData] = useState(initialValue);
  const handleDataChange = (event) => {
    setData(event.target.value || "");
    handleChange(event.target.value || "");
  };
  return (
      <FormControl sx={{ m: 1, minWidth: 120 }}  size="small">
        <InputLabel id={`${name}`}>{name}</InputLabel>
        <Select
          labelId={`${name}`}
          id={`input-of-${name}`}
          value={data}
          onChange={handleDataChange}
          input={<OutlinedInput label="input" />}
        >
          {options?.map(({ name, value }) => (
            <MenuItem
              key={`filter option value ${value}+${name}`}
              value={value}
              aria-label={name}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
  );
}
