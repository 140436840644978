import { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import styles from "./style.module.css";

export default function DropDown({ handleOptionChange, list = [], name="Select", initialValue="" }) {
  
  const [data, setData] = useState({ name: initialValue });

  const handleChange = (event) => {
    const value = event.target.value;
    setData({ name: value });
    handleOptionChange(value);
  };

  return (
    <div className={styles.mb}>
      <FormControl sx={{ minWidth: 140, maxWidth: 140 }} size="small" required>
        <InputLabel id="general list">{name}</InputLabel>
        <Select
          labelId="general list"
          id="general list"
          value={data.name}
          label={"Select"}
          name={data.name}
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>Please Select</em>
          </MenuItem>
          {list.map((value) => (
            <MenuItem key={value} value={value} name={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
