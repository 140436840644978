import { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

function tableCreator(tableData, filterValue = "") {
  return tableData.filter((obj) => {
    const validTransaction =
      obj?.transactionFor !== undefined &&
      obj?.transactionFor !== null &&
      obj?.transactionType !== undefined &&
      obj?.transactionType !== null;

    const filterString = filterValue.toLowerCase();

    return (
      filterValue === "all" ||
      filterValue === "" ||
      filterValue === undefined ||
      (validTransaction &&
        (obj.transactionFor.toLowerCase().includes(filterString) ||
          obj.transactionType.toLowerCase().includes(filterString)))
    );
  });
}

export default function DisplayTable({ tableHeader, tableData, filterValue }) {
  const tableContent =
    tableData?.status === "successfull"
      ? tableCreator(tableData?.data, filterValue)
      : [];

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: "100%" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: "rgb(43, 131, 127)",
                fontWeight: "bold",
                fontSize: "large",
              }}
            >
              {tableHeader.map((column) => (
                <TableCell
                  key={column.id + "tableHeader" + column.label}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  sx={{ backgroundColor: "rgb(43, 131, 127)", color: "white" }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableContent
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {tableHeader.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={tableContent.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
