import * as React from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import Button from '@mui/material/Button'
import style from './style.module.css'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ButtonGroup
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { BsEyeFill } from 'react-icons/bs'
import {
  usePutQueryMutation,
  usePostHotelMutation,
  usePostFlightMutation
} from '../../../../store/crm'

import Alert from '../../../alert'

import { RiHotelFill } from 'react-icons/ri'
import { ImCross } from 'react-icons/im'
import { PiAirplaneTiltFill } from 'react-icons/pi'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import QuotationTextBox from '../../quotationTextbox/index'

const  EnquiryDataTab =  React.memo(({ client })=>{
  let navigate = useNavigate();
  const [state, setState] = React.useState({
    left: false
  })

  const [setHotelUpd] = usePostHotelMutation()
  const [setFlightUpd] = usePostFlightMutation()

  const [travellersClassBtn, setTravellersClassBtn] = useState({
    economy: true,
    primiumEconomy: false,
    business: false
  })

  const [attend, setAttend] = useState('')

  const [alert, setAlert] = useState({
   hotel:false,
   flight:false
  })

  React.useEffect(() => {
    const attendent = localStorage.getItem('attend')
    setAttend(attendent)
  }, [])

  const handletravellerEcoClassBtn = () => {
    setTravellersClassBtn({
      economy: true,
      primiumEconomy: false,
      business: false
    })
    setQueryUpd({
      ...queryUpd,
      Flights: {
        ...queryUpd.Flights,
        class: 'economy'
      }
    })
  }

  const handletravellerPremiumEcoClassBtn = () => {
    setTravellersClassBtn({
      economy: false,
      primiumEconomy: true,
      business: false
    })

    setQueryUpd({
      ...queryUpd,
      Flights: {
        ...queryUpd.Flights,
        class: 'first'
      }
    })
  }

  const handletravellerBusinessEcoClassBtn = () => {
    setTravellersClassBtn({
      economy: false,
      primiumEconomy: false,
      business: true
    })
    setQueryUpd({
      ...queryUpd,
      Flights: {
        ...queryUpd.Flights,
        class: 'business'
      }
    })
  }

  const [sendQuery, { isLoading, isSuccess }] = usePutQueryMutation()
  const [filghtDestinations, setFlightDestinations] = React.useState([])

  const addNewFlightDestination = () => {
    setFlightDestinations([
      ...filghtDestinations,
      { source: '', destination: '', departure: '', return: '' }
    ])
  }

  const handleFlightDestinationChange = (index, field, value) => {
    const updatedDestinations = [...filghtDestinations]
    updatedDestinations[index][field] = value
    setFlightDestinations(updatedDestinations)
  }

  const handleRemoveFlightDestination = index => {
    const updatedDestinations = [...filghtDestinations]
    updatedDestinations.splice(index, 1)
    setFlightDestinations(updatedDestinations)
  }

  const [queryUpd, setQueryUpd] = React.useState({
    id: client._id,
    followUp: '',
    remarkUpd: '',
    status: client.status,
    updDate: '',
    hotel: false,
    flight: false,
    // Hotels: { destinations: [], noOfRooms: '', details: '', status: '' },
    // Flights: { destinations: [], details: '', class: 'economy', status: '' }
    quote:''
  })

  const [quotes, setQuotes] = React.useState("")

  const [hotel, setHotel] = useState({
    id:client._id,
    destinations: [],
    noOfRooms: '',
    details: '',
    status: 'enquired',
    quote:''
  })

  const [flight, setFlight] = useState({
    id:client._id,
    destinations: [],
    details: '',
     class: 'economy',
    status: 'enquired',
    quote:''
  })

  const [quote, setQuote] = useState({
    hotel:'',
    flight:''
  })

  const [errors, setErrors] = React.useState({
    remarks: false
  })

  const [enqType, setEnqType] = React.useState({
    hotel: false,
    flight: false
  })

  const toggleDrawer = (anchor, open) => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setState({ ...state, [anchor]: open })
  }

  const handleClick = async () => {


    console.log(queryUpd.remarkUpd.length)
    var myVal = "";
    
    if(queryUpd.status==="quoted"){
      if(client.flight && client.Flights.status==="enquired"){
        setAlert({
          ...alert,
          flight:true
        })
        setTimeout(()=>{
          setAlert({
            ...alert,
            flight:false
          })
        },2500)
        return
      }

      if(client.hotel && client.Hotels.status==="enquired"){
        setAlert({
          ...alert,
          hotel:true
        })
        setTimeout(()=>{
          setAlert({
            ...alert,
            hotel:false
          })
        },2500)
        return
      }

      // if(client.hotel && client.Hotels.status==="quoted"){
      //   setQuotes((prev)=> prev+ createTable('Hotel',client.Hotels.quote))
      //   myVal += createTable('Hotel',client.Hotels.quote)
      // }   
      
      // if(client.flight && client.Flights.status==="quoted"){
      //   console.log(createTable('Flight',client.Flights.quote))
      //   setQuotes((prev)=> prev + createTable('Flight', client.Flights.quote))
      //   myVal += createTable('Flight',client.Flights.quote)

      //   // setQuotes((prev)=> "prev" + "hello")
      // }   

    }
    
    // console.log("=-=-><>",quotes, "-=-=<><", myVal)
    
    if (queryUpd.remarkUpd.length === 0) {
      setErrors({
        ...errors,
        remarks: true
      })
      return
    }

    queryUpd.followUp = parseInt(client.followup) + 1
    queryUpd.updDate = Date.now();
    queryUpd.id = client._id;
    queryUpd.quote = myVal;

    // queryUpd.Hotels.destinations = [...destinations];
    // queryUpd.Flights.destinations = [...filghtDestinations];
    // queryUpd.hotel=enqType.hotel;
    // queryUpd.flight=enqType.flight;

    // if (enqType.hotel) {
    //   queryUpd.Hotels.status = 'enquired'
    // }

    // if (enqType.flight) {
    //   queryUpd.Flights.status = 'enquired'
    // }

    var res = await sendQuery(queryUpd)

    if (res.data?.status === 'success') {
      setQueryUpd({
        ...queryUpd,
        remarkUpd: '',
        hotel: false,
        flight: false,
   
      })

      setEnqType({
        hotel: false,
        flight: false
      })

      setDestinations([])
      setState({
        left: false
      })
      setErrors({
        remarks: false
      })
    }
    myVal = "";
  }

  const handleRemoveDestination = index => {
    const updatedDestinations = [...destinations]
    updatedDestinations.splice(index, 1)
    setDestinations(updatedDestinations)
  }

  const [destinations, setDestinations] = React.useState([])

  const addNewDestination = () => {
    setDestinations([...destinations, { name: '', checkIn: '', checkOut: '' }])
  }

  const handleDestinationChange = (index, field, value) => {
    const updatedDestinations = [...destinations]
    updatedDestinations[index][field] = value
    setDestinations(updatedDestinations)
  }

  const addHotel = () => {
    hotel.destinations = [...destinations]
    hotel.id=client._id;
    setHotelUpd(hotel)
    setEnqType({
      ...enqType,
      hotel:false
    })
  }

  const handleFlight = ()=>{
    flight.destinations = [...filghtDestinations]
    flight.id=client._id;
    setFlightUpd(flight)
    setEnqType({
      ...enqType,
      flight:false
    })
  }

  const handleSubmitQuotation = ()=>{
    const handledby = localStorage.getItem('user')

      //  let obj = {
      //   id:client._id,
      //   quote:true,
      //   Hotels:client.Hotels,
      //   hotelQuote:quote.hotel,
      //   status:'quoted',
      //   handledby:handledby
      //  }    
      //  setHotelUpd(obj)
      //  setState({left:false})
  }

  const handleSubmitFlightQuotation = ()=>{
    const handledby = localStorage.getItem('user')
       var obj = {
        id:client._id,
        quote:true,
        Flights:client.Flights,
        flightQuote:quote.flight,
        status:'quoted',
        handledby:handledby
       }    

       setFlightUpd(obj)
  }

  const list = anchor => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : '100%' }}
      role='presentation'
    >

    <Alert text='Hotel Quotation is pending' status='error' alert={alert.hotel} />
    <Alert text='Flight Quotation is pending' status='error' alert={alert.flight} />
      
      <div className={style.headings}>
        <div className={style.insideHeading}>
          <p>Enquiry No. : </p>
          <p>{client.enquiryNo}</p>
        </div>

        <div className={style.insideHeading}>
          <p>Enquiry Type : </p>
          <p>{client.enquiryType}</p>
        </div>
      </div>

      <div className={style.edit}>
        <RiHotelFill
          className={style.iconHtl}
          onClick={() =>
            setEnqType({
              ...enqType,
              hotel: !enqType.hotel
            })
          }
        />

        <PiAirplaneTiltFill
          className={style.iconHtl}
          onClick={() =>
            setEnqType({
              ...enqType,
              flight: !enqType.flight
            })
          }
        />
      </div>

      <div className={style.custDetailHeading}>
        <p>Customer Details</p>
      </div>

      <div className={style.custDetails}>
        <div className={style.custDetailsBtm}>
          <p className={style.title}>Customer Name : </p>
          <p className={style.val}>{client.customerName}</p>
        </div>

        <div className={style.custDetailsBtm}>
          <p className={style.title}>Contact No. : </p>
          <p className={style.val}>{client.contactNo}</p>     
        </div>

        <div className={style.custDetailsBtm}>
          <p className={style.title}>Email Id. : </p>
          <p className={style.val}>{client.emailId}</p>
        </div>

        <div className={style.custDetailsBtm}>
          <p className={style.title}>Whatsapp No.: </p>
          <p className={style.val}>{client.whatsappNo}</p>
        </div>

        <div className={style.custDetailsBtm}>
          <p className={style.title}>Company Name: </p>
          <p className={style.val}>{client.companyName}</p>
        </div>

        <div className={style.custDetailsBtm}>
          <p className={style.title}>HandledBy: </p>
          <p className={style.val}>{client.handledBy}</p>
        </div>
      </div>

      <Divider />

      <div className={style.custDetailHeading}>
        <p>Enquiry Details</p>
      </div>

      <div className={style.custDetails}>
        <div className={style.custDetailsBtm}>
          <p className={style.title}>Enquired On : </p>
          <p className={style.val}>{client.createdAt.split('T')[0]}</p>
        </div>

        <div className={style.custDetailsBtm}>
          <p className={style.title}>Last Updated : </p>
          <p className={style.val}>{client.updatedAt.split('T')[0]}</p>
        </div>

        <div className={style.custDetailsBtm}>
          <p className={style.title}>Enquired Area : </p>
          <p className={style.val}>{client.enquiredArea}</p>
        </div>

        <div className={style.custDetailsBtm}>
          <p className={style.title}>Country : </p>
          <p className={style.val}>{client.country}</p>
        </div>

        <div className={style.custDetailsBtm}>
          <p className={style.title}>Referred By : </p>
          <p className={style.val}>{client.referredBy}</p>
        </div>
      </div>

      <Divider />

      <div className={style.custDetailHeading}>
        <p>Pax Details</p>
      </div>

      <div className={style.custDetails}>
        <div className={style.custDetailsBtm}>
          <p className={style.title}>No. of Adult : </p>
          <p className={style.val}>{client.noOfAdult}</p>
        </div>

        <div className={style.custDetailsBtm}>
          <p className={style.title}>No. of Child : </p>
          <p className={style.val}>{client.noOfChild}</p>
        </div>

        {client.childAge.map((item, index) => {
          return (
            <div className={style.custDetailsBtm}>
              <p className={style.title}>Child{index + 1} Age : </p>
              <p className={style.val}>{item}</p>
            </div>
          )
        })}
      </div>

      {client.hotel && (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
            sx={{ bgcolor: '#64CCC5' }}
          >
            <p className={style.accordTitle}>Hotel</p>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            {client.Hotels.destinations.map(item => {
              return (
                <>
                  <div className={style.accordBody}>
                    <div className={style.custDetailsBtm}>
                      <p className={style.title}>Destination : </p>
                      <p className={style.val}>{item.name}</p>
                    </div>

                    <div className={style.custDetailsBtm}>
                      <p className={style.title}>Check-In : </p>
                      <p className={style.val}>{item.checkIn}</p>
                    </div>

                    <div className={style.custDetailsBtm}>
                      <p className={style.title}>Check-Out: </p>
                      <p className={style.val}>{item.checkOut}</p>
                    </div>
                  </div>
                  <Divider />
                </>
              )
            })}

            <div className={style.accordBody}>
              <div className={style.custDetailsBtm}>
                <p className={style.title}>No. of Room : </p>
                <p className={style.val}>{client.Hotels.noOfRooms}</p>
              </div>

              <div className={style.custDetailsBtm}>
                <p className={style.title}>Status : </p>
                <p className={style.val}>{client.Hotels.status}</p>
              </div>

              <div className={style.custDetailsBtm}>
                <p className={style.title}>Details : </p>
                <p className={style.val}>{client.Hotels.details}</p>
              </div>

              <div className={style.custDetailsBtm}>
                <p className={style.title}>Handled By : </p>
                <p className={style.val}>{client.Hotels.handledBy}</p>
              </div>

            
            </div>
             {client.Hotels?.quote?.length>0&& 
            <div className={style.custDetailsBt}>
                <p className={style.title}>Quotation : </p>
                <textarea
                    rows={5}
                    className={style.textare}
                    placeholder='Enter your Quotation'
                    value={client.Hotels.quote}
                    // onChange={e => {
                    //  setQuote({
                    //   ...quote,
                    //    hotel:e.target.value,
                    //  })
                    // }}
                  ></textarea>
                {/* <p className={style.val}>{client.Hotels.quote}</p> */}
              </div>
              }
             
            {client.Hotels.status === 'enquired' &&
            attend === 'hotel' &&
            client.status === 'pending' ? (
              <>
                <div className={style.textare}>
                  {/* <textarea
                    rows={5}
                    className={style.textare}
                    placeholder='Enter your Quotation'
                    value={quote.hotel}
                    onChange={e => {
                     setQuote({
                      ...quote,
                       hotel:e.target.value,
                     })
                    }}
                  ></textarea> */}

                  <QuotationTextBox  client={client} typeofBooking={"Hotel"}/>

                </div>

                <Button variant='outlined' color='primary' sx={{ margin: 2 }} onClick={handleSubmitQuotation}>
                  submit Quotation
                </Button>
              </>
            ) : null}
          </AccordionDetails>
        </Accordion>
      )}

      {enqType.hotel && (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
            sx={{ bgcolor: '#64CCC5', color: 'white' }}
          >
            <p className={style.accordTitle}> Hotel / Package</p>
          </AccordionSummary>
          <AccordionDetails>
            {destinations.map((destination, index) => (
              <div key={index} className={style.subAccordance}>
                <TextField
                  size='small'
                  variant='outlined'
                  placeholder={`Destination ${index + 1}`}
                  value={destination.name}
                  onChange={e =>
                    handleDestinationChange(index, 'name', e.target.value)
                  }
                  InputProps={{
                    style: {
                      fontFamily: 'Comfortaa',
                      width: '200px',
                      fontSize: '12px'
                    }
                  }}
                />

                <TextField
                  size='small'
                  variant='outlined'
                  type='date'
                  label='Check-In'
                  value={destination.checkIn}
                  onChange={e =>
                    handleDestinationChange(index, 'checkIn', e.target.value)
                  }
                  InputProps={{
                    style: {
                      fontFamily: 'Comfortaa',
                      width: '200px',
                      fontSize: '12px'
                    }
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                />

                <TextField
                  size='small'
                  type='date'
                  label='Check-Out'
                  value={destination.checkOut}
                  onChange={e =>
                    handleDestinationChange(index, 'checkOut', e.target.value)
                  }
                  InputProps={{
                    style: {
                      fontFamily: 'Comfortaa',
                      width: '200px',
                      fontSize: '12px'
                    }
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                />

                <Button
                  variant='outlined'
                  color='error'
                  name={index}
                  onClick={() => handleRemoveDestination(index)}
                >
                  <ImCross />
                </Button>
              </div>
            ))}
            <Button
              variant='contained'
              size='small'
              color='primary'
              sx={{ mt: 3, textTransform: 'none' }}
              onClick={addNewDestination}
            >
              Add Destination
            </Button>
            <div className={style.subAccordance}>
              <FormControl size='small' sx={{ width: '200px' }}>
                <InputLabel
                  size='small'
                  sx={{
                    width: '200px',

                    fontSize: '13px',
                    fontFamily: 'comfortaa'
                  }}
                  id='demo-simple-select-label'
                >
                  No. of Room(s)
                </InputLabel>
                <Select
                  size='small'
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  label='No. of Room(s)'
                  MenuProps={{
                    PaperProps: {
                      style: { maxHeight: '200px' }
                    }
                  }}
                  value={hotel.noOfRooms}
                  onChange={e => {
                    setHotel({
                      ...hotel,
                      noOfRooms: e.target.value
                    })
                  }}
                >
                  {menuItems}
                </Select>
              </FormControl>
            </div>

            <div className={style.remarksData}>
              <TextField
                id='outlined-multiline-static'
                label='Details'
                sx={{ width: '100%' }}
                multiline
                rows={2}
                InputProps={{
                  style: {
                    fontFamily: 'Comfortaa',
                    fontSize: '12px'
                  }
                }}
                value={hotel.details}
                onChange={e => {
                  setHotel({
                    ...hotel,
                    details: e.target.value
                  })
                }}
              />
            </div>
            <Button
              variant='outlined'
              color='primary'
              size='small'
              onClick={addHotel}
            >
              Add
            </Button>
          </AccordionDetails>
        </Accordion>
      )}

      {client.flight && (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
            sx={{ bgcolor: '#64CCC5' }}
          >
            <p className={style.accordTitle}>Flight</p>
          </AccordionSummary>
          <AccordionDetails>
            {client.Flights.destinations.map(item => {
              return (
                <>
                  <div className={style.accordBody}>
                    <div className={style.custDetailsBtm}>
                      <p className={style.title}>Source : </p>
                      <p className={style.val}>{item.source}</p>
                    </div>

                    <div className={style.custDetailsBtm}>
                      <p className={style.title}>Destination : </p>
                      <p className={style.val}>{item.destination}</p>
                    </div>

                    <div className={style.custDetailsBtm}>
                      <p className={style.title}>Departure: </p>
                      <p className={style.val}>{item.departure}</p>
                    </div>

                    <div className={style.custDetailsBtm}>
                      <p className={style.title}>Return: </p>
                      <p className={style.val}>{item.return}</p>
                    </div>
                  </div>
                  <Divider />
                </>
              )
            })}
            <div className={style.accordBody}>
              <div className={style.custDetailsBtm}>
                <p className={style.title}>Class : </p>
                <p className={style.val}>{client.Flights.class}</p>
              </div>
              <div className={style.custDetailsBtm}>
                <p className={style.title}>Status : </p>
                <p className={style.val}>{client.Flights.status}</p>
              </div>
              <div className={style.custDetailsBtm}>
                <p className={style.title}>Details : </p>
                <p className={style.val}>{client.Flights.details}</p>
              </div>
              <div className={style.custDetailsBtm}>
                <p className={style.title}>Handled By : </p>
                <p className={style.val}>{client.Flights.handledBy}</p>
              </div>
            </div>


            {client.Flights?.quote?.length>0 && 
            <div className={style.custDetailsBt}>
                <p className={style.title}>Quotation : </p>
                <textarea
                    rows={5}
                    className={style.textare}
                    placeholder='Enter your Quotation'
                    value={client.Flights.quote}
                    // onChange={e => {
                    //  setQuote({
                    //   ...quote,
                    //    hotel:e.target.value,
                    //  })
                    // }}
                  ></textarea>
                {/* <p className={style.val}>{client.Hotels.quote}</p> */}
              </div>
              }
            {client.Flights.status === 'enquired' &&
            attend === 'flight' &&
            client.status === 'pending' ? (
              <>
                <div className={style.textare}>
                  <textarea
                    rows={5}
                    className={style.textare}
                    placeholder='Enter your Quotation'
                    value={quote.flight}
                    onChange={e => {
                     setQuote({
                      ...quote,
                       flight:e.target.value,
                     })
                    }}
                  ></textarea>
                </div>

                <Button variant='outlined' color='primary' sx={{ margin: 2 }} onClick={handleSubmitFlightQuotation}>
                  submit Quotation
                </Button>
              </>
            ) : null}
          </AccordionDetails>
        </Accordion>
      )}

      {enqType.flight && (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
            sx={{ bgcolor: '#64CCC5', color: 'white' }}
          >
            <p className={style.accordTitle}> Flight Update</p>
          </AccordionSummary>
          <AccordionDetails>
            {filghtDestinations.map((item, index) => {
              return (
                <div key={index} className={style.subAccordance}>
                  <TextField
                    size='small'
                    variant='outlined'
                    type='text'
                    value={item.source}
                    onChange={e =>
                      handleFlightDestinationChange(
                        index,
                        'source',
                        e.target.value
                      )
                    }
                    placeholder='Source'
                    InputProps={{
                      style: {
                        fontFamily: 'Comfortaa',
                        width: '200px',

                        fontSize: '12px'
                      }
                    }}
                  />

                  <TextField
                    size='small'
                    variant='outlined'
                    type='text'
                    value={item.destination}
                    onChange={e =>
                      handleFlightDestinationChange(
                        index,
                        'destination',
                        e.target.value
                      )
                    }
                    placeholder='Destination'
                    InputProps={{
                      style: {
                        fontFamily: 'Comfortaa',
                        width: '200px',

                        fontSize: '12px'
                      }
                    }}
                  />

                  <TextField
                    size='small'
                    variant='outlined'
                    type='date'
                    value={item.departure}
                    onChange={e =>
                      handleFlightDestinationChange(
                        index,
                        'departure',
                        e.target.value
                      )
                    }
                    label='Departure'
                    InputProps={{
                      style: {
                        fontFamily: 'Comfortaa',
                        width: '200px',

                        fontSize: '12px'
                      }
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />

                  <TextField
                    size='small'
                    type='date'
                    value={item.return}
                    onChange={e =>
                      handleFlightDestinationChange(
                        index,
                        'return',
                        e.target.value
                      )
                    }
                    label='Return'
                    InputProps={{
                      style: {
                        fontFamily: 'Comfortaa',
                        width: '200px',

                        fontSize: '12px'
                      }
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />

                  <Button
                    variant='outlined'
                    color='error'
                    name={index}
                    onClick={() => handleRemoveFlightDestination(index)}
                  >
                    <ImCross />
                  </Button>
                </div>
              )
            })}

            <Button
              onClick={addNewFlightDestination}
              sx={{ mt: 5, textTransform: 'none' }}
              size='small'
              variant='contained'
              color='primary'
            >
              Add Route
            </Button>

            <div className={style.classDiv}>
              <p>Choose travellers class</p>
              <ButtonGroup
                variant='outlined'
                aria-label='outlined primary button group'
                size='small'
                sx={{ height: '30px' }}
              >
                <Button
                  size='small'
                  sx={{
                    textTransform: 'none',
                    fontSize: '12px',
                    fontFamily: 'comfortaa'
                  }}
                  variant={
                    travellersClassBtn.economy ? 'contained' : 'outlined'
                  }
                  onClick={handletravellerEcoClassBtn}
                  className={style.btnClass}
                >
                  Economy/Premium Economy
                </Button>
                <Button
                  size='small'
                  sx={{
                    textTransform: 'none',
                    fontSize: '12px',
                    fontFamily: 'comfortaa'
                  }}
                  className={style.btnClass}
                  onClick={handletravellerPremiumEcoClassBtn}
                  variant={
                    travellersClassBtn.primiumEconomy ? 'contained' : 'outlined'
                  }
                >
                  First
                </Button>
                <Button
                  size='small'
                  sx={{
                    textTransform: 'none',
                    fontSize: '12px',
                    fontFamily: 'comfortaa'
                  }}
                  className={style.btnClass}
                  onClick={handletravellerBusinessEcoClassBtn}
                  variant={
                    travellersClassBtn.business ? 'contained' : 'outlined'
                  }
                >
                  Business
                </Button>
              </ButtonGroup>
            </div>

            <div className={style.remarksData}>
              <TextField
                id='outlined-multiline-static'
                label='Details '
                sx={{ width: '100%' }}
                multiline
                rows={2}
                InputProps={{
                  style: {
                    fontFamily: 'Comfortaa',
                    fontSize: '12px'
                  }
                }}
                value={flight.details}
                onChange={e => {
                  setFlight({
                    ...flight,
                   details:e.target.value
                  })
                }}
              />
            </div>

            <Button variant="outlined" color="primary" onClick={handleFlight}>
              Add
            </Button>
          </AccordionDetails>
        </Accordion>
      )}

      {client.visa && (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
            sx={{ bgcolor: '#64CCC5' }}
          >
            <p className={style.accordTitle}>Visa</p>
          </AccordionSummary>
          <AccordionDetails>
            <div className={style.accordBody}>
              <div className={style.accordBody}>
                <div className={style.custDetailsBtm}>
                  <p className={style.title}>Pancard No. : </p>
                  <p className={style.val}>{client.Visa.pancard}</p>
                </div>

                <div className={style.custDetailsBtm}>
                  <p className={style.title}>Passport No. : </p>
                  <p className={style.val}>{client.Visa.passport}</p>
                </div>

                <div className={style.custDetailsBtm}>
                  <p className={style.title}>Aadhar-Card No. : </p>
                  <p className={style.val}>{client.Visa.aadharCard}</p>
                </div>

                <div className={style.custDetailsBtm}>
                  <p className={style.title}>Address: </p>
                  <p className={style.val}>{client.Visa.address}</p>
                </div>

                <div className={style.custDetailsBtm}>
                  <p className={style.title}>Company Address: </p>
                  <p className={style.val}>{client.Visa.companyAddress}</p>
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      )}

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
          sx={{ bgcolor: '#64CCC5' }}
        >
          <p className={style.accordTitle}>Previous Remarks & Follow Ups</p>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          <div className={style.accordBody}>
            {client.follow?.map(item => {
              return (
               
                  <div key={item.followUp} className={style.custDetailsRemark}>
                    <div className={style.custDetailsBtm}>
                      <p className={style.title}>FollowUp : </p>
                      <p className={style.val}>{item.followUp}</p>
                    </div> 

                    <div className={style.custDetailsBtm}>
                      <p className={style.title}>Status : </p>
                      <p className={style.val}>{item.status}</p>
                    </div>

                    <div className={style.custDetailsBtm}>
                      <p className={style.title}>updated At : </p>
                      <p className={style.val}>{upd(item.updDate)}</p>
                    </div>

                    <div className={style.custDetailsBtm}>
                      <p className={style.title}>Remarks : </p>
                      <p className={style.val}>{item.Remarks}</p>
                    </div>
                  </div>
               
              )
            })}
          </div>
        </AccordionDetails>
      </Accordion>

      <Divider />
    
      {client?.handledBy === localStorage.getItem('user') ? <>
     
      <div className={style.custDetailHeading}>
        <p>Follow Ups & Remarks</p>
      </div>

      {/* <div className={style.custDetails}>
        <div className={style.custDetailsBtm}>
          <p className={style.title}>FollowUp : </p>
          <p className={style.val}>{parseInt(client.followup) + 1}</p>
        </div>

        <div className={style.custDetailsBtm}>
          <TextField
            sx={{ width: '100%' }}
            multiline
            rows={3}
            placeholder='Remarks '
            InputProps={{
              style: {
                fontFamily: 'Comfortaa',
                width: '300px',

                fontSize: '12px'
              }
            }}
            value={queryUpd.remarkUpd}
            onChange={e =>
              setQueryUpd({
                ...queryUpd,
                remarkUpd: e.target.value
              })
            }
            error={errors.remarks}
            helperText={errors.remarks && <p>Enter Remarks</p>}
          />
        </div>
      </div> */}
    
      <Divider />

      {/* <div className={style.custDetails}>
        <div className={style.custDetailsBtm}>
          <FormControl size='small' sx={{ width: '150px', mt: 2 }}>
            <InputLabel
              size='small'
              sx={{
                width: '150px',

                fontSize: '13px',
                fontFamily: 'comfortaa'
              }}
              id='demo-simple-select-label'
            >
              Status
            </InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              label='Status'
              value={queryUpd.status}
              onChange={e => {
                setQueryUpd({ ...queryUpd, status: e.target.value })
              }}
            >
              <MenuItem value='pending'>Pending</MenuItem>
              <MenuItem value='quoted'> Quote</MenuItem>
              <MenuItem value='lost'> Lost</MenuItem>
              <MenuItem value='confirm'> Confirm</MenuItem>
              <MenuItem value='canceled'> Cancel</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className={style.custDetailsBtm}>
        
        </div>
      </div> */}
      <Divider />
      <div className={style.custDetails}>
        <Button
          variant='outlined'
          color='error'
          onClick={() => setState({ left: false })}
        >
          Back
        </Button>
        {/* {isLoading ? (
          <Button variant='outlined' color='success' sx={{ width: '100px' }}>
            <CircularProgress size='1.5rem' color='success' />
          </Button>
        ) : (
          <Button
            variant='contained'
            color='success'
            onClick={handleClick}
            sx={{ width: '100px' }}
          >
            Submit
          </Button>
        )} */}

        <Button variant="contained" color="info" onClick={()=>navigate(`/query/${client._id}`)}>
          Change Status
        </Button> 
      </div>
      </> :  <Button
          variant='outlined'
          color='error'
          sx={{mt:2,ml:2}}
          onClick={() => setState({ left: false })}
        >
          Back
        </Button>}
    </Box>
  )

  return (
    <div>
      {['left'].map(anchor => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>
            {' '}
            <BsEyeFill />{' '}
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  )
})

const upd = time => {
  var dte = new Date(time).toLocaleString()
  // console.log(dte)
  return <>{dte}</>
}

const menuItems = Array.from({ length: 41 }, (_, index) => (
  <MenuItem key={index} value={index}>
    {index}
  </MenuItem>
))

export default EnquiryDataTab


// const createTable = (heading, val)=>{
//   console.log(val.split('.'))
//   var split = val.split('.').map((item)=>{  return `<span style="border:2px solid black;"> ${item}</span><br/>`})
//   console.log("sp",split)
//   var sp = "";
//  split.forEach((item)=> {
//     sp+= item;
//  })
//   return `
//   <div style="border:2px solid black;">
//   <table >
//   <tr>
//     <td style="color:red;">${heading}</td>
//   <td>
//   ${sp}
//   </td>
//   </tr>
//   </table>
//   </div>
//   `
// }

