export const transactionHeader = [
  { id: "enquiryNo", label: "Enquiry No.", minWidth: 100 },
  { id: "clientName", label: "Client Name", minWidth: 100 },
  { id: "supplierName", label: "Supplier Name", minWidth: 100 },
  { id: "handledBy", label: "Handled By", minWidth: 100 },
  { id: "billStatus", label: "Bill Status", minWidth: 100 },
  { id: "clientPay", label: "Client Pay", minWidth: 100 },
  { id: "bookingType", label: "Service", minWidth: 100 },
  {
    id: "supplierNetAmount",
    label: "Buy Amount",
    minWidth: 100,
    align: "right",
    format: (value) => value.toFixed(2),
  },
  {
    id: "clientNetAmount",
    label: "Sell Amount",
    minWidth: 100,
    align: "right",
    format: (value) => value.toFixed(2),
  },
  {
    id: "profit",
    label: "Profit",
    minWidth: 100,
    align: "right",
    format: (value) => value.toFixed(2),
  },
  {
    id: "edit",
    label: "Edit",
    minWidth: "fit-content",
    align: "center",
  },
  {
    id: "detail",
    label: "Details",
    minWidth: "fit-content",
    align: "center",
  },
];

export const ledgerHeader = [
  { id: "clientName", label: "Client Name", minWidth: 100 },
  { id: "transactionType", label: "Service", minWidth: 100 },
  { id: "transactionFor", label: "Transaction For", minWidth: 100 },
  { id: "remarks", label: "Remarks", minWidth: 100 },
  { id: "refNo", label: "Reference No.", minWidth: 100 },
  {
    id: "amountDebited",
    label: "Amount Debited",
    minWidth: 100,
    align: "right",
    format: (value) => value.toFixed(2),
  },
  {
    id: "amountCredited",
    label: "Amount Credited",
    minWidth: 100,
    align: "right",
    format: (value) => value.toFixed(2),
  },
  {
    id: "currentBalance",
    label: "Current Balance",
    minWidth: 100,
    align: "right",
    format: (value) => value.toFixed(2),
  },
];

export const billingHeader = [
  { id: "enquiryNo", label: "Enquiry No.", minWidth: 100, align: "left" },
  { id: "clientName", label: "Client Name", minWidth: 200, align: "right" },
];

export const innerBillingHeader = [
  { id: "date", label: "Date", minWidth: 100, align: "left" },
  {
    id: "transactionId",
    label: "Transaction Id",
    minWidth: 200,
    align: "left",
  },
  { id: "bookingType", label: "Booking Type", minWidth: 100, align: "left" },
  { id: "handledBy", label: "Handled By", minWidth: 150, align: "left" },
  { id: "profit", label: "Profit", minWidth: 100, align: "right" },

  {
    id: "billStatus",
    label: "Billing Status",
    minWidth: 100,
    align: "right",
  },
  { id: "details", label: "Details", minWidth: 100, align: "right" },
];
