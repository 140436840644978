// assets
import {  IconBrandBooking, IconBrandDouban  } from '@tabler/icons';

// constant
const icons = {  IconBrandBooking, IconBrandDouban };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
  id: 'website',
  title: 'Website',
  type: 'group',
  children: [
    {
      id: 'website',
      title: 'EuropaMundo',
      type: 'item',
      url: '/website',
      icon: icons.IconBrandBooking,
      breadcrumbs: false
    },
  ]
};

export default dashboard;
