import { useState } from "react";
import { useGetLedgerQuery } from "../../store/crm";
import DisplayTable from "../../components/accounts/DisplayTable/index.jsx";
import Filter from "../../components/accounts/Filter";
import {ledgerHeader} from '../../utils/constants/tablesHeader'
import styles from './style.module.css';

export default function LedgerHistory() {
  const [value, setValue] = useState("All");
  const { data: ledgerData } = useGetLedgerQuery();

  const handleChange = (val) => {
    setValue(val);
  };

  return (
    <div className={styles.wrapper}>
      <Filter handleFilterChange={handleChange} />
      <DisplayTable tableHeader={ledgerHeader} tableData={ledgerData} filterValue={value}/>
    </div>
  );
}
