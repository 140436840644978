import { useState } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Button } from "@mui/material";
import {
  billingHeader,
  innerBillingHeader,
} from "utils/constants/tablesHeader";
import { useGetBillingQuery } from "store/crm";
import Navigation from "components/buttons/Navigation";
import ReadMoreIcon from "@mui/icons-material/ReadMore";

function Row({ billingData }) {
  const { enquiryNo, clientName, transactions } = billingData;
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {enquiryNo}
        </TableCell>
        <TableCell align="right">{clientName}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                History
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    {innerBillingHeader?.map((column) => (
                      <TableCell key={column.id} align={column.align}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactions?.map(
                    ({
                      _id,
                      createdAt,
                      bookingType,
                      handledBy,
                      billing_completed,
                      client_serviceChrg_amount,
                      supplier_commission,
                    }) => (
                      <TableRow key={_id}>
                        <TableCell component="th" scope="row">
                          {createdAt?.slice(0, 10)}
                        </TableCell>
                        <TableCell align="left">{_id}</TableCell>
                        <TableCell
                          align="left"
                          minWidth="100"
                          sx={{ textTransform: "uppercase" }}
                        >
                          {bookingType}
                        </TableCell>
                        <TableCell align="left" minWidth="200">
                          {handledBy}
                        </TableCell>
                        <TableCell align="right">
                          {parseFloat(client_serviceChrg_amount) +
                            parseFloat(supplier_commission) || 0}
                        </TableCell>
                        <TableCell align="right">
                          {billing_completed ? (
                            <Button
                              variant="outlined"
                              color="success"
                              size="small"
                            >
                              Done
                            </Button>
                          ) : (
                            <Button
                              variant="outlined"
                              color="error"
                              size="small"
                            >
                              Pending
                            </Button>
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <Navigation
                            route={`/transaction/details/${_id}`}
                            buttonText={<ReadMoreIcon />}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function CollapsibleTable() {
  const { data } = useGetBillingQuery();
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow
            sx={{
              backgroundColor: "rgb(43, 131, 127)",
              color: "white",
              fontWeight: "bold",
              fontSize: "large",
            }}
          >
            <TableCell />
            {billingHeader?.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                sx={{ backgroundColor: "rgb(43, 131, 127)", color: "white" }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.data.map((row) => (
            <Row key={row.enquiryNo} billingData={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
