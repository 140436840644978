import styles from "./style.module.css";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

export default function SingleFieldShow({
  name = "",
  value = "",
  isRupeeSign = false,
}) {
  return (
    <>
      {value ? (
        <div className={styles.parent}>
          <span className={styles.fieldName}>{name}</span>
          {isRupeeSign ? (
            <span className={styles.valueName}>
              <CurrencyRupeeIcon className={styles.iconSize} />
              {value}
            </span>
          ) : (
            <span className={styles.valueName}>{value}</span>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
