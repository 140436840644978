import { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import styles from "./style.module.css";

const list = ["True", "False"];

export default function BooleanOption({ handleOptionChange, name = "Select", initialValue="" }) {
  const [isTrue, setIsTrue] = useState(initialValue);

  const handleChange = (event) => {
    const value = event.target.value;
    setIsTrue(value);
    value === "True" ? handleOptionChange(true) : handleOptionChange(false);
  };

  return (
    <div className={styles.my}>
      <FormControl sx={{ minWidth: 140, maxWidth: 140 }} size="small" required>
        <InputLabel id="boolean list">{name}</InputLabel>
        <Select
          labelId="boolean list"
          id="boolean list"
          value={isTrue}
          label={"Select"}
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>Please Select</em>
          </MenuItem>
          {list.map((value) => (
            <MenuItem key={value + name + "boolean options"} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
