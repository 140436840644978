import { useState } from "react";
import { useParams } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import styles from "./styles.module.css";
import DropDown from "components/accounts/DropDown";
import { paymentMode } from "../../../../utils/constants/accounts";
import BooleanOption from "components/accounts/BooleanOption";
import { usePutTransactionMutation } from "store/crm";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
  hover: {
    "&:hover": {
      backgroundColor: "#000",
    },
  },
};

const userId = localStorage.getItem("id");
const userName = localStorage.getItem("user");

export default function ApprovePay({ otherValues }) {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [putTransaction] = usePutTransactionMutation();
  const {
    supplierPaymentPending,
    supplierPaymentMode,
    clientPaymentPending,
    clientPaymentMode,
    billingCompleted,
  } = otherValues;

  const [supplierPayPending, setSupplierPayPending] = useState(
    supplierPaymentPending || true
  );
  const [clientPayPending, setClientPayPending] = useState(
    clientPaymentPending || true
  );
  const [supplierPayMode, setSupplierPayMode] = useState(
    supplierPaymentMode || ""
  );
  const [clientPayMode, setClientPayMode] = useState(clientPaymentMode || "");
  const [billStatus, setBillStatus] = useState(billingCompleted || false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSupplierPayPending = (value) => {
    setSupplierPayPending(value);
  };

  const handleClientPayPending = (value) => {
    setClientPayPending(value);
  };

  const handleBillStatus = (value) => {
    setBillStatus(value);
  }

  const handleSubmit = async () => {
    const transactionDetails = {
      id: id,
      accountEmployId: userId,
      accountEmployName: userName,
      supplier_payment_pending: supplierPayPending,
      supplier_payment_mode: supplierPayMode,
      client_payment_pending: clientPayPending,
      client_payment_mode: clientPayMode,
      billing_completed: billStatus,
    };

    const { data } = await putTransaction(transactionDetails);

    if (data?.status === "successfull") {
      alert("Update successfully.");
    } else {
      alert("Please try again later.");
    }
  };

  return (
    <div>
      <Button
        sx={{
          backgroundColor: "#5e35b1",
          "&:hover": {
            backgroundColor: "#5e35b1",
          },
          color: "white",
          fontWeight: 600,
          margin: "1rem 0",
          borderRadius: "0.5rem",
          padding: "0.6rem",
        }}
        onClick={handleOpen}
      >
        Approve Payment
      </Button>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h4" component="h3">
              Please confirm the below transaction details.
            </Typography>

            <div className={styles.flexContainer}>
              <span className={styles.textLine}>Is payment pending?</span>

              <div className={styles.gridContainer}>
                <BooleanOption
                  handleOptionChange={handleSupplierPayPending}
                  name={"Supplier"}
                  initialValue={supplierPaymentPending ? "True" : "False"}
                />
                <BooleanOption
                  handleOptionChange={handleClientPayPending}
                  name={"Client"}
                  initialValue={clientPaymentPending ? "True" : "False"}
                />
              </div>

              <span className={styles.textLineTwo}>Payment mode?</span>
              <div className={styles.gridContainer}>
                <DropDown
                  list={paymentMode}
                  initialValue={supplierPaymentMode}
                  name={"Supplier"}
                  handleOptionChange={(value) => setSupplierPayMode(value)}
                />
                <DropDown
                  list={paymentMode}
                  initialValue={clientPaymentMode}
                  name={"Client"}
                  handleOptionChange={(value) => setClientPayMode(value)}
                />
              </div>

              <span className={styles.textLine}>Is billing pending?</span>
              <div className={styles.gridContainer}>
                <BooleanOption
                  handleOptionChange={handleBillStatus}
                  name={"Bill Status"}
                  initialValue={billingCompleted ? "True" : "False"}
                />
              </div>
            </div>

            <Button
              sx={{
                backgroundColor: "#5e35b1",
                "&:hover": {
                  backgroundColor: "#5e35b1",
                },
                color: "white",
                fontWeight: 600,
                margin: "1rem 0",
                borderRadius: "0.5rem",
                padding: "0.6rem",
              }}
              onClick={handleSubmit}
            >
              Approve Payment
            </Button>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
