// assets
import { IconFilePencil,IconFilePower  } from '@tabler/icons';
import { GrDocumentPerformance } from "react-icons/gr";

// constant
const icons = { IconFilePencil, IconFilePower };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
  id: 'enquiry',
  title: 'Enquiry',
  type: 'group',
  children: [
    {
      id: 'enquiry',
      title: 'Total Enquiry',
      type: 'item',
      url: '/queries/all',
      icon: icons.IconFilePower,
      breadcrumbs: false
    },
    {
        id: 'newEnquiry',
        title: 'Add New Enquiry',
        type: 'item',
        url: '/queries/add',
        icon: icons.IconFilePencil,
        breadcrumbs: false
      },
      {
        id: 'myEnquiry',
        title: 'My Enquiries',
        type: 'item',
        url: '/queries/my',
        icon: icons.IconFilePower,
        breadcrumbs: false
      },
      {
        id: 'Visa',
        title: 'Visa Enquiry',
        type: 'item',
        url: '/queries/visa',
        icon: icons.IconFilePower,
        breadcrumbs: false
      }
  ]
};

export default dashboard;
