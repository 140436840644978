// assets
import {  IconBrandBooking, IconBrandDouban  } from '@tabler/icons';

// constant
const icons = {  IconBrandBooking, IconBrandDouban };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
  id: 'client',
  title: 'client',
  type: 'group',
  children: [
    {
      id: 'client',
      title: 'Total Clients',
      type: 'item',
      url: '/clients',
      icon: icons.IconBrandBooking,
      breadcrumbs: false
    },
  ]
};

export default dashboard;
