import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";

import { memo, useState } from "react";

import { usePostHotelMutation } from "store/crm";


const TextEditor = memo(({client, typeofBooking})=>{
  const [setHotelUpd] = usePostHotelMutation();
  const [textEditor, setTextEditor] = useState("");



  const data = {
    textEditor: textEditor,
  };


  const handleSubmitQuotation = (e)=>{
    e.preventDefault();
    const handledby = localStorage.getItem('user')

       let obj = {
        id:client._id,
        quote:true,
        [typeofBooking]:client[typeofBooking],
        hotelQuote:data.textEditor,
        status:'quoted',
        handledby:handledby
       }    
      //  setHotelUpd(obj)

   console.log("data", obj)

     
  
    }


  function handleSubmit(e) {
    e.preventDefault();
   console.log("data", data)
  }

  return (
    <>
    <form onSubmit={handleSubmitQuotation}>
      <CKEditor
        editor={ClassicEditor}
        data={textEditor}
        onReady={(editor) => {
          // You can store the "editor" and use when it is needed.
        }}
        onChange={(event, editor) => {
          const data = editor.getData();

          setTextEditor(data);
          console.log("-=-<><",editor.getData())
        }}
      />
      <button type="submit">Submit</button>
    </form>
    
    <div dangerouslySetInnerHTML={{ __html: data.textEditor }}>
      
    </div>
    </>
  );
})

export default TextEditor;



