import { useRoutes } from 'react-router-dom';
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import { useEffect } from 'react';

const token = localStorage.getItem('token');

export default function ThemeRoutes() {
  
  const connectionWarmPool = async()=>{
    const response = await fetch(process.env.REACT_APP_URL)
    const resp = await response
    if(resp){
      console.log(response.status,"Conection Warm pool loading...")
    }

  }

  useEffect(()=>{
   setInterval(connectionWarmPool,3000)
  },[])
  const routes = useRoutes([token ? MainRoutes : AuthenticationRoutes ]);

  return routes;
}
