import { lazy } from 'react';

// project imports
import MainLayout from '../layout/MainLayout';
import Loadable from 'components/ui-component/Loadable';
import {Navigate} from 'react-router-dom'
import LedgerHistory from 'pages/ledgerHistory';
import MyEnquiry from 'components/enquiry/MyEnquiry';
import VisaEnquiry from 'pages/billing/billingStatus/index';
import TransactionDisplaySideBySide from 'components/accounts/TransactionDisplaySideBySide';
import BillingDisplayTable from 'components/accounts/BillingDisplayTable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('../pages/dashboard/Default')));



const AllQuery = Loadable(lazy(() => import('../components/enquiry')));
const QueryById = Loadable(lazy(() => import('../pages/query')));
const AddQuery = Loadable(lazy(() => import('../components/enquiry/enquiryForm/containHeader')));
const QueryByStatus = Loadable(lazy(() => import('../components/query/queryList')));
const TransactionHistory  = Loadable(lazy(() => import('pages/transactionHistory')));
const Suplier  = Loadable(lazy(() => import('pages/supplier')));
const AccountsRule = Loadable(lazy(() => import('pages/accountsRule')))
const Billing = Loadable(lazy(() => import('pages/billing')))
const AddEmployee = Loadable(lazy(() => import('../components/employee/addNewEmployee')));
const EmployeeDetails = Loadable(lazy(() => import('../components/employee/employeeDetails')));
const UpdateEmployee = Loadable(lazy(() => import('../pages/updateEmployee')));
const Website = Loadable(lazy(() => import('../pages/website')))
const Clients = Loadable(lazy(() => import('../pages/customer')))
const EditEnquiry = Loadable(lazy(()=>import("../components/enquiry/enquiryFormEdit")))





// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element:  <MainLayout /> ,
  children: [
    {
      path: '/',
      element: <DashboardDefault />
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'default',
          element: <DashboardDefault />
        }
      ]
    },
    {
      path: 'employee',
      children: [
        {
          path: 'list',
          element: <EmployeeDetails />
        },
        {
          path: 'add',
          element: <AddEmployee />
        },
        {
          path: 'update/:id',
          element: <UpdateEmployee />
        },
      ]
    },
    {
      path: 'queries',
      children: [
        {
          path: 'all',
          element: <AllQuery />
        },
        {
          path: 'add',
          element: <AddQuery />
        },
        {
          path: 'my',
          element: <MyEnquiry />
        },
        {
          path: 'visa',
          element: <VisaEnquiry />
        },
      ]
    },
    {
      path: '/query/:queryId',
      element: <QueryById />
    },
    {
      path: '/queries/:status',
      element: <QueryByStatus/>
    },
    {
      path: '/supplier',
      element: <Suplier/>
    },
    
   

   
   
    {
      path: 'accountsrule',
      element: <AccountsRule />
    },
    {
      path: 'billing/:enqId',
      element: <Billing />
    },
    {
      path: 'editQuery/:enqId',
      element: <EditEnquiry />
    },
    
    {
      path: '/transaction',
      element: <TransactionHistory />
    },
    {
      path: '/billing',
      element: <BillingDisplayTable />
    },
    {
      path: '/transaction/details/:id',
      element: <TransactionDisplaySideBySide/>
    },
    {
      path: '/ledger',
      element: <LedgerHistory/>
    },
    {
      path: '/website',
      element: <Website/>
    },
    {
      path: '/clients',
      element: <Clients/>
    },
    {
      path: '*',
      element: <Navigate to='/' replace />,
    },
  ]
};

export default MainRoutes;
