import { useParams } from "react-router-dom";
import useGetTransactionSchema from "../../../hooks/accounts/useGetTransactionSchema";
import {
  useGetTransactionQuery,
  useGetSupplierQuery,
  useGetCustomerQuery,
  useGetQueryByEnquiryNoQuery,
} from "../../../store/crm";
import styles from "./style.module.css";
import SingleFieldShow from "../FieldsLayout/SingleFieldShow";
import CollapsibleFields from "../FieldsLayout/CollapsibleFields";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import ApprovePay from "../ActionLayout/ApprovePay";
import EditCustomer from "../../enquiry/enquiryTable/customerDetails";
export default function TransactionDisplaySideBySide() {
  const { id } = useParams();
  const { data } = useGetTransactionQuery();
  const transactionData = data?.data?.find(
    (transaction) => transaction._id === id
  );

  const { data: supplierApi } = useGetSupplierQuery();
  const supplierApiData = supplierApi?.data.find(
    (supplier) => supplier._id === transactionData?.supplierId
  );

  const { data: customerApi } = useGetCustomerQuery();
  const customerApiData = customerApi?.data.find(
    (customer) => customer._id === transactionData?.clientId
  );

  const {
    supplierData,
    supplierDetail,
    clientData,
    clientDetail,
    commonData,
    names,
    otherValues,
  } = useGetTransactionSchema(
    transactionData,
    supplierApiData,
    customerApiData
  );

  const enquiryNumber = commonData?.at(0)?.value;
  const { data: enquiryDetails } = useGetQueryByEnquiryNoQuery(enquiryNumber);

  return (
    <main className={styles.fullNot}>
      <section className={styles.gridContainerTop}>
        {commonData?.map((item) => (
          <SingleFieldShow
            key={`Up-${item.name}-${item.value}`}
            name={item.name}
            value={item.value}
          />
        ))}
        <div className={styles.profit}>
          <span className={styles.filedName}>Profit:</span>
          <span className={styles.values}>
            <CurrencyRupeeIcon sx={{ fontSize: "medium" }} />
            {otherValues?.profit}
          </span>
        </div>
      </section>

      <div className={styles.wrapper}>
        <section className={styles.left}>
          <h3>Supplier:</h3>
          <div className={styles.border}>
            <div className={styles.nameLine}>
              <span className={styles.name}>Supplier Name:</span>
              <span className={styles.value}>{names?.supplierName}</span>
            </div>

            <div className={styles.gridContainer}>
              {supplierDetail?.map((item) => (
                <SingleFieldShow
                  key={`SingleFieldShow-Left-${item.name} + ${item.value}`}
                  name={item.name}
                  value={item.value}
                />
              ))}
            </div>
          </div>
          <div className={styles.flexContainer}>
            {supplierData?.map((item) => (
              <>
                {item.hasOwnProperty("list") ? (
                  <CollapsibleFields
                    key={`CollapsibleFields-Left-${item.name}`}
                    listName={item.name}
                    list={item}
                  />
                ) : (
                  <SingleFieldShow
                    key={`single-Left-${item.name}`}
                    name={item.name}
                    value={item.value}
                    isRupeeSign={item.isRupeeSign}
                  />
                )}
              </>
            ))}
            <div className={styles.borderUp}>
              <span className={styles.highlights}>Net Amount:</span>
              <span className={styles.values}>
                <CurrencyRupeeIcon sx={{ fontSize: "medium" }} />
                {otherValues?.supplierNet}
              </span>
            </div>
          </div>
        </section>

        <section className={styles.right}>
          <h3>Client:</h3>
          <div className={styles.border}>
            <div className={styles.nameLine}>
              <span className={styles.name}>Client Name:</span>
              <span className={styles.value}>{names?.clientName}</span>
              {enquiryDetails?.data && (
                <EditCustomer client={enquiryDetails?.data} />
              )}
            </div>

            <div className={styles.gridContainer}>
              {clientDetail?.map((item) => (
                <SingleFieldShow
                  key={`SingleFieldShow-Right-${item.name} + ${item.value}`}
                  name={item.name}
                  value={item.value}
                />
              ))}
            </div>
          </div>
          <div className={styles.flexContainer}>
            {clientData?.map((item) => (
              <>
                {item.hasOwnProperty("list") ? (
                  <CollapsibleFields
                    key={`CollapsibleFields-Right-${item.name}`}
                    listName={item.name}
                    list={item}
                  />
                ) : (
                  <SingleFieldShow
                    key={`single-Right-${item.name}`}
                    name={item.name}
                    value={item.value}
                    isRupeeSign={item.isRupeeSign}
                  />
                )}
              </>
            ))}
            <div className={styles.borderUp}>
              <span className={styles.highlights}>Net Amount:</span>
              <span className={styles.values}>
                <CurrencyRupeeIcon sx={{ fontSize: "medium" }} />
                {otherValues?.clientNet}
              </span>
            </div>
          </div>
        </section>
      </div>
      <ApprovePay otherValues={otherValues} />
    </main>
  );
}
