import { useState, useEffect } from "react";
import styles from "./style.module.css";

const filterOption = ["all", "client", "supplier", "employee", "flight", "hotel", "visa"];

export default function Filter({ handleFilterChange }) {
  const [isActive, setIsActive] = useState("all");

  useEffect(() => {
    handleFilterChange(isActive);
  }, [isActive, handleFilterChange]);

  const handleChange = (event) => {
    const value = event.target.value;
    setIsActive(value);
  };

  return (
    <div className={styles.wrapper}>
      {filterOption.map((value) => (
        <button
          key={value + "filter-Option"}
          onClick={handleChange}
          value={value}
          className={
            isActive === value
              ? `${styles.text} ${styles.active}`
              : `${styles.text}`
          }
        >
          {value}
        </button>
      ))}
    </div>
  );
}
